<script lang="ts" setup>
</script>

<template>
    <ul class="list-group">
        <router-link to="/my/supplier" class="list-group-item list-group-item-action" active-class="active">
            Постачальник
        </router-link>

        <router-link to="/my/warehouse" class="list-group-item list-group-item-action" active-class="active">
            Склад
        </router-link>

        <router-link to="/my/warehouse/upload" class="list-group-item list-group-item-action" active-class="active">
            Завантаження складу
        </router-link>

        <router-link to="/my/warehouse/upload/status" class="list-group-item list-group-item-action" active-class="active">
            Статус завантаження
        </router-link>

        <router-link to="/my/subscription" class="list-group-item list-group-item-action" active-class="active">
            Послуги
        </router-link>
    </ul>
</template>

<style scoped>

</style>